<template>
  <div>
    <Modal
      v-model="showModal"
      width="800"
      @on-ok="onConfirm"
    >
      <p
        slot="header"
        class="text-center"
      >质检提示</p>
      <div v-if="list && list.length">
        <p class="m-b-10"><span class="text-orange">提示：若关联的制作任务未完成，质检通过将不会同步完成质检。</span></p>
        <div class="m-b-10">
          <Checkbox v-model="single">同步质检关联的制作任务</Checkbox>
        </div>
        <div>
          <Table
            size="small"
            stripe
            :data="list"
            :columns="tableColumns"
          ></Table>
        </div>
      </div>
      <div v-else>
        <span>确定要质检当前所选择的任务？</span>
      </div>
    </Modal>
  </div>
</template>

<script>
import { confirmTaskFinish } from '@/api/msp/taskitem'
import { getProduceTaskList } from '@/api/msp/plan'
export default {
  // props: {
  //   taskitemIds: {
  //     type: Array,
  //     default: new Array(0)
  //   }
  // },
  data () {
    return {
      taskitemIds: [],
      showModal: false,
      single: true,
      list: [],
      tableColumns: [
        { title: '资源编号', align: 'center', key: 'code' },
        { title: '任务类型', align: 'center', key: 'typeName' },
        { title: '状态', align: 'center', key: 'statusName' }
      ]
    }
  },
  methods: {
    init (selectedIds = []) {
      this.taskitemIds = selectedIds
      if (this.taskitemIds.length === 0) {
        this.$Notice.warning({ desc: '当前未选中任务项，无法操作。' })
        return false
      }
      this.single = true
      this.getListData()
      this.showModal = true
    },
    /**
     * 获取关联制作任务列表数据
     */
    getListData () {
      this.list = []
      this.resCodes = ''
      getProduceTaskList({ taskitemIds: JSON.stringify(this.taskitemIds) }).then(res => {
        if (res && !res.errcode) {
          this.list = res
        }
      })
    },
    onConfirm () {
      const produceTaskitemIds = (this.single && this.list.length) ? JSON.stringify(this.list.map(x => x.id)) : ''

      const postData = {
        taskitemIds: JSON.stringify(this.taskitemIds),
        produceTaskitemIds: produceTaskitemIds
      }
      confirmTaskFinish(postData).then(res => {
        if (res && res.errcode === 0) {
          this.$emit('refreshTable', res)
        }
      })
    }
  }
}
</script>
