<template>
  <div>
    <Modal
      v-model="choiceTrainModal"
      width="800"
      footer-hide
    >
      <p
        slot="header"
        class="text-center"
      >确认列车</p>
      <Row
        :gutter="8"
        class="m-b-10"
      >
        <i-col
          span="22"
          class="text-right"
        >
          <i-input
            v-model="query.keyword"
            size="small"
            placeholder="关键字：资源编号"
          ></i-input>
        </i-col>
        <i-col span="2">
          <Button
            type="primary"
            icon="ios-search"
            size="small"
            @click="handleSearch"
          >搜索</Button>
        </i-col>
      </Row>
      <div>
        <Table
          stripe
          size="small"
          :data="tableList"
          :columns="tableColumns"
        ></Table>
        <div class="paging_style">
          <Page
            size="small"
            :total="total"
            :page-size="query.pageSize"
            show-total
            show-elevator
            :current="query.pageNumber"
            @on-change="handleChangePage"
          ></Page>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
// import { getResourcePage } from '@/api/product/resource'
import { getPublisherDevicePage } from '@/api/rim/device'
import { updateTrainInfo } from '@/api/msp/demandV3'
export default {
  data () {
    return {
      taskitemId: null,
      choiceTrainModal: false,
      query: {
        pageNumber: 1,
        pageSize: 15,
        assetId: null,
        enabled: 1,
        devicemodel: 400,
        keyword: ''
      },
      total: 0,
      tableList: [],
      tableColumns: [
        // { title: '资产', align: 'center', key: 'assetName' },
        { title: '编号', align: 'center', key: 'code' },
        { title: '车型', align: 'center', key: 'stationName' },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('a', {
              on: {
                click: () => {
                  this.handleConfirm(params.row)
                }
              }
            }, '选择')
          }
        }
      ]
    }
  },
  methods: {
    initData (data) {
      this.choiceTrainModal = true
      this.taskitemId = data ? data.taskitemId : this.taskitemId
      this.query.assetId = data ? data.assetId : this.query.assetId
      getPublisherDevicePage(this.query).then(res => {
        if (res && !res.errcode) {
          this.tableList = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.tableList = []
          this.total = 0
          this.query.pageNumber = 1
        }
      })
    },
    handleChangePage (curPage) {
      this.query.pageNumber = curPage
      this.initData()
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.initData()
    },
    handleConfirm (params) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认列车后该资源对应订单内资源的其他任务列车编号会一并确认，确认选择该列车？',
        onOk: () => {
          updateTrainInfo({ deviceId: params.deviceId, taskitemId: this.taskitemId }).then(res => {
            if (res && res.errcode === 0) {
              this.choiceTrainModal = false
              this.$emit('loadPageData')
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    }
  }
}
</script>
