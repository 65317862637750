<template>
  <div>
    <Modal
      v-model="modalShow"
      width="1000"
    >
      <p
        slot="header"
        class="text-center"
      >关联任务</p>
      <div>
        <Table
          stripe
          size="small"
          :data="list"
          :columns="tableColumns"
        ></Table>
      </div>
    </Modal>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { formartMspPlanStatus, formartMspTaskitemRephotoStatus } from '@/utils/tagStatus'
import { getRelatedTaskitem } from '@/api/msp/plan'
export default {
  data () {
    return {
      modalShow: false,
      list: [],
      tableColumns: [
        {
          title: '资源编号',
          key: 'resourceCode',
          render: (h, data) => {
            return h('span', {}, data.row.resourceCode || '无')
          }
        },
        {
          title: '位置',
          key: 'positionName',
          render: (h, data) => {
            return h('span', {}, data.row.positionName || data.row.stationName)
          }
        },
        { title: '任务类型', key: 'typeName' },
        {
          title: '记账金额',
          render: (h, params) => {
            const html = []
            if (params.row.taskitemCharges && params.row.taskitemCharges.length) {
              params.row.taskitemCharges.forEach(item => {
                const innerHtml = h('div', [
                  h('span', `${item.costTypeName}：`),
                  h('span', {
                    style: { color: '#44bd32', fontWeight: 'bold' }
                  }, toMoney(item.confirmCost))
                ])
                html.push(innerHtml)
              })
            }
            return h('div', html)
          }
        },
        { title: '质检时间', key: 'qualityTestTime' },
        {
          title: '状态',
          width: 120,
          align: 'center',
          render: (h, data) => {
            // return h('tag', { props: { color: formatMspPlanStatusColor(data.row.status) } }, data.row.statusName)
            return h('div', [
              formartMspPlanStatus(h, data.row.status, data.row.statusName),
              formartMspTaskitemRephotoStatus(h, data.row.rephoto)
            ])
          }
        },
        {
          title: '质检',
          width: 80,
          align: 'center',
          render: (h, data) => {
            if (data.row.qualityTesting) {
              return h('Icon', {
                props: { type: 'md-checkbox-outline', size: 16, color: '#19be6b' }
              })
            } else {
              return h('Icon', {
                props: { type: 'md-close', size: 16, color: '#ed4014' }
              })
            }
          }
        },
        {
          title: '重拍',
          width: 80,
          align: 'center',
          render: (h, data) => {
            if (data.row.rephoto) {
              return h('Icon', {
                props: { type: 'md-checkbox-outline', size: 16, color: '#19be6b' }
              })
            } else {
              return h('Icon', {
                props: { type: 'md-close', size: 16, color: '#ed4014' }
              })
            }
          }
        }
      ]
    }
  },
  methods: {
    initShow (obj) {
      this.list = []
      if (obj.taskitemId && obj.taskitemType) {
        this.modalShow = true
        getRelatedTaskitem({ taskitemId: obj.taskitemId, taskitemType: obj.taskitemType }).then(res => {
          if (res && !res.errcode) {
            this.list.push(res)
          }
        })
      }
    }
  }
}
</script>
