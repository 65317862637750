<template>
  <div>
    <h4 class="workplatform-title m-b-10">工单资源详情</h4>

    <Row :gutter="6" class="m-b-10">
      <i-col :span="showType === 1 ? 16 : 6">
        <Button size="small" type="success" class="m-r-5" :disabled="btnConfirm"
          v-if="isAuth('inspection_index_confrim')" @click="handleConfirmTask(null)">确认质检</Button>
        <Button size="small" type="success" class="m-r-5" :disabled="btnConfirm" v-if="isAuth('inspection_index_check')"
          @click="handleCheckTask()">质检</Button>
        <Button size="small" type="success" class="m-r-5" v-if="isAuth('inspection_index_fastcheck')"
          @click="onFastCheck">便捷质检</Button>
        <Button size="small" type="success" class="m-r-5" :disabled="btnCheckAmount"
          v-if="isAuth('inspection_index_checkamount')" @click="handleCheckAmount">确认对账</Button>
        <Button size="small" class="m-r-5" type="warning" v-if="isAuth('inspection_index_remake')"
          @click="handleRemakePhotoTask(null)">批量重拍</Button>
        <!-- <Button
          size="small"
          class="m-l-5"
          type="primary"
          @click="handleSetFiles"
        >设置素材</Button> -->
        <Button v-if="showType === 1" size="small" class="m-r-5" type="primary" icon="md-analytics"
          @click="stationSvgModal = true">显示地图点位分布</Button>
      </i-col>
      <i-col v-if="showType === 2" span="3">
        <Select v-model="planDetailQuery.taskitemType" size="small" placeholder="任务类型" :clearable="true"
          @on-change="handleSearchList">
          <Option v-for="item in taskTypeArray" :key="'t_' + item.value" :value="item.value">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col v-if="showType === 2" span="3">
        <Select v-model="planDetailQuery.qualityTesting" size="small" placeholder="质检状态" :clearable="true"
          @on-change="handleSearchList">
          <Option v-for="item in statusArray" :key="'status_' + item.value" :value="item.value">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col v-if="showType === 2" span="3">
        <DatePicker transfer size="small" v-model="planDetailQuery.actionDate" format="yyyy-MM-dd" placeholder="作业日期"
          style="width:100%" clearable></DatePicker>
      </i-col>
      <i-col span="6" class="text-right">
        <i-input v-model="planDetailQuery.keyword" size="small" placeholder="关键字：资源编号、客户名称"></i-input>
      </i-col>
      <i-col span="2">
        <Button type="primary" icon="ios-search" size="small" @click="handleSearchList">搜索</Button>
      </i-col>
    </Row>
    <Row :gutter="8" class="m-b-5">
      <i-col span="12">
        <p class="remark">提示：“确认质检”和“重拍”，只对已完成的任务有效！</p>
      </i-col>
      <i-col span="12">
        <div class="text-right">
          <Page size="small" show-sizer show-total :total="tableData.totalRow" :page-size="planDetailQuery.pageSize"
            :page-size-opts="pagesizeSetting" :current="planDetailQuery.pageNumber"
            @on-page-size-change='changePageSize' @on-change="handlePageNumberChange" />
        </div>
      </i-col>
    </Row>
    <Table stripe show-summary max-height="620" size="small" :data="tableData.list" :columns="tableColumns"
      :summary-method="handleSummary" @on-selection-change="selectionTable"></Table>
    <Row :gutter="8">
      <i-col span="6">
        <div class="m-t-5" style="line-height:25px;">当前已选中<span style="color:#ff9900;padding:0 3px">{{
      selectedResourceArray.length }}</span>条</div>
      </i-col>
      <i-col span="18">
        <div class="paging_style">
          <Page size="small" :total="tableData.totalRow" :page-size="planDetailQuery.pageSize" show-total show-elevator
            show-sizer :page-size-opts="pagesizeSetting" @on-page-size-change='changePageSize'
            :current="planDetailQuery.pageNumber" @on-change="handlePageNumberChange"></Page>
        </div>
      </i-col>
    </Row>
    <planAttachInfo ref="modalPlanAttachInfo" @on-reloadList="handleCheckSuccess" @on-confirm-check="handleCheckTask" />
    <SvgStation ref="svgStation" :stationSvgModal.sync="stationSvgModal" :stationId="curDomainId" :svgShowType="4"
      :showAllDemandButton="false" :searchSchedules="searchSchedlue" @svg-finished="svgFinished"
      @on-select="handleSelectSvgResource" @handleChange3dMode="handleBeginRevice3d" />
    <ThreeStation ref="threeStation" @three-finished="threeFinished" @handleChangeSvgMode="handleChangeSvg" />
    <SvgMaintenanceTask ref="SvgMaintenanceTask" />

    <Modal width="800" v-model="modifyCostModal">
      <p slot="header" class="text-center">计费调整</p>
      <div class="p-b-10 workplatform-title">选择费用进行调整</div>
      <div class="m-b-10">
        <RadioGroup v-model="costId" @on-change="handleChoiceCost">
          <Radio v-for="item in taskCostArray" :key="'taskCost_' + item.id" :label="item.id"><span class="p-r-10">{{
      item.costTypeName }}</span><span class="p-r-10">计费单价：{{ formartMoney(item.confirmChargeUnitCost)
              }}</span><span class="p-r-10">计费数量：{{ item.confirmChargeQuantity + item.confirmChargeTypeName
              }}</span><span>计费金额：{{ formartMoney(item.confirmCost) }}</span>
          </Radio>
        </RadioGroup>
      </div>
      <div class="p-t-10 workplatform-title">可调整计费项</div>
      <Row :gutter="8">
        <i-col span="4"><span class="title">计费单位</span>
          <Select v-model="costModel.chargeType" size="small">
            <Option v-for="(unit, index) in unitArray" :key="index" :value="unit.id">{{ unit.name }}</Option>
          </Select>

        </i-col>
        <i-col span="4"><span class="title">计费数量</span>
          <InputNumber v-model="costModel.chargeQuantity" style="width:100%" size="small" placeholder="请填写调整项金额">
          </InputNumber>
        </i-col>
        <i-col span="4"><span class="title">计费单价(¥)</span>
          <InputNumber v-model="costModel.unitCost" style="width:100%" size="small" placeholder="请填写调整项金额">
          </InputNumber>
        </i-col>
        <i-col span="9"><span class="title">备注</span>
          <Input v-model="costModel.remark" style="width:100%" size="small" placeholder="请填写备注" />
        </i-col>
        <i-col span="3">
          <span class="title">操作</span>
          <Button type="success" size="small" v-if="isAuth('inspection_index_fee')" @click="confirmUpdate">确认调整</Button>
        </i-col>
      </Row>
      <div>
        <div class="p-t-10 workplatform-title">计费项变更历史</div>
        <Table stripe size="small" :data="historylist" :columns="historyColumn"></Table>
      </div>

      <div slot="footer">
        <Button type="text" @click="modifyCostModal = false">取消</Button>
      </div>
    </Modal>
    <Modal width="800" v-model="checkModal" @on-ok="handleConfirmCheck">
      <p slot="header" class="text-center">批量质检确认信息</p>
      <div>
        <div class="m-b-10">质检验收确认明细</div>
        <Table stripe size="small" :data="checkData" :columns="checkColumns"></Table>
      </div>
    </Modal>
    <train-list ref="trainList" @loadPageData="initData"></train-list>
    <relation-task ref="relationTask"></relation-task>
    <Modal v-model="visiblePreview" width="800" :footer-hide="true">
      <p slot="header" class="text-center">{{ fileName }}</p>
      <img-light-box ref="imgLightBox" :showModal="visiblePreview" :sortIndex.sync="sortIndex"></img-light-box>
    </Modal>
    <SetFeedbackPic ref="setFeedbackPic" @refData="handleCheckSuccess" />
    <FastCheck ref="fastCheck" @on-adjust-cost="onAdjustCost" @on-confrim-fast-check="handleConfirmTask"
      @on-retake="handleRemakePhotoTask" />
    <CheckTask ref="checkTask" @refreshTable="handleCheckSuccess"/>
    <sku-task-detail ref="skuTask"/>
  </div>
</template>

<script>
import { commonMixins } from '@/assets/mixins/common'
import { sysMixins } from '@/assets/mixins/sys'
import { toMoney } from '@/utils/wnumb_own'
import { GetCurrentSchedule, ParseDate } from '@/utils/dateFormat'
import planAttachInfo from '@/components/msp/planAttachInfo'
import SvgStation from '@/components/svg/SvgStation'
import ThreeStation from '@/components/svg/ThreeStation'
import SvgMaintenanceTask from '@/components/svg/SvgMaintenanceTask'
import TrainList from './TrainList.vue'
import RelationTask from './RelationTask.vue'
import ImgLightBox from '@/components/common/ImgVideoLightBox'
import SetFeedbackPic from './SetFeedbackPic.vue'
import FastCheck from './FastCheck.vue'
import CheckTask from './CheckTask.vue'
import SkuTaskDetail from '@/components/checkAccept/SkuTaskDetail.vue'

import { getPlanDetailPage, getPlanTasktypeFeeStatistic } from '@/api/msp/plan'
import { confirmFinish, remakePhoto, getTaskItemList, confirmAmount } from '@/api/msp/taskitem'
import { formartMspPlanStatus, formartMspTaskitemRephotoStatus, formartMspChargeStatus } from '@/utils/tagStatus'
import { getFeeModifyList, modifycharge } from '@/api/msp/settlement'
import { getElecDeviceModelList } from '@/api/msp/taskother'

export default {
  mixins: [commonMixins, sysMixins],
  components: {
    planAttachInfo,
    SvgStation,
    ThreeStation,
    SvgMaintenanceTask,
    TrainList,
    RelationTask,
    ImgLightBox,
    SetFeedbackPic,
    FastCheck,
    CheckTask,
    SkuTaskDetail
  },
  data () {
    return {
      sortIndex: 0,
      visiblePreview: false,
      fileName: '',
      pagesizeSetting: [15, 50, 100, 200, 500],
      btnConfirm: true, // 确认质检按钮
      btnCheckAmount: true, // 发起对账按钮
      tableList: [],
      total: 0,
      tableColumns: [],
      columns: new Map([
        [1, ['selection', 'code', 'position', 'typeName', 'skuName', 'adTypeName', 'schedule', 'actionFile', 'charge', 'qualityTestTime', 'createCompanyName', 'supplier', 'status', 'qualityTesting', 'rephoto', 'operate']], // 按作业日期
        [2, ['selection', 'code', 'domainName', 'position', 'typeName', 'skuName', 'adTypeName', 'actionDate', 'actionFile', 'schedule', 'charge', 'qualityTestTime', 'createCompanyName', 'supplier', 'status', 'qualityTesting', 'rephoto', 'operate']] // 按客户品牌
      ]),
      tableColumnArray: {
        selection: {
          type: 'selection',
          width: 60,
          align: 'center',
          fixed: 'left',
          key: 'selection'
        },
        code: {
          title: '资源编号',
          key: 'resourceCode',
          minWidth: 90,
          render: (h, data) => {
            return h('span', {}, data.row.resourceCode || '无')
          }
        },
        domainName: { title: '站点', key: 'domainName', minWidth: 80 },
        actionDate: { title: '作业日期', key: 'actionDate', minWidth: 100 },
        actionFile: {
          title: '作业画面',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            if (params.row.taskitemFiles && params.row.taskitemFiles.length > 0) {
              if (params.row.taskitemFiles.some(x => (x.mimeType.includes('video') || x.mimeType.includes('audio')))) {
                return h('a', {
                  on: {
                    click: () => {
                      const postData = {
                        startIndex: 0,
                        imgList: params.row.taskitemFiles
                      }
                      this.sortIndex = 0
                      this.fileName = params.row.stationName
                      this.$refs.imgLightBox.init(postData)
                      this.visiblePreview = true
                    }
                  },
                  style: {
                    cursor: 'pointer'
                  }
                }, '查看素材')
              } else {
                return h('div', [
                  h(
                    'img',
                    {
                      domProps: {
                        src: params.row.taskitemFiles[0].fileKey + '?x-oss-process=image/resize,w_64'
                      },
                      on: {
                        click: () => {
                          const postData = {
                            startIndex: 0,
                            imgList: params.row.taskitemFiles
                          }
                          this.sortIndex = 0
                          this.fileName = `${params.row.domainName} ${params.row.code}`
                          this.variableTitle = ''
                          this.curShowFileArray = params.row.taskitemFiles
                          this.$refs.imgLightBox.init(postData)
                          this.visiblePreview = true
                        }
                      },
                      style: {
                        width: '64px',
                        cursor: 'pointer'
                      }
                    }
                  )
                ])
              }
            } else {
              return h('span', '暂无画面')
            }
          }
        },
        position: {
          title: '位置',
          key: 'positionName',
          minWidth: 80,
          render: (h, data) => {
            return h('span', {}, data.row.positionName || data.row.stationName)
          }
        },
        typeName: { title: '任务类型', key: 'typeName', minWidth: 90 },
        adTypeName: { title: '用途', key: 'adTypeName', align: 'center', minWidth: 80 },
        skuName: {
          title: '单品',
          key: 'skuName',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            if (params.row.skuId > -1) {
              return h('a', {
                on: {
                  click: () => {
                    const data = {
                      taskId: params.row.taskId,
                      skuId: params.row.skuId,
                      resourceCode: params.row.resourceCode,
                      skuName: params.row.skuName

                    }
                    this.onShowSkuTask(data)
                  }
                }
              }, params.row.skuName)
            } else {
              return h('span', '/')
            }
          }
        },
        schedule: {
          title: '订单档期',
          minWidth: 120,
          key: 'schedule',
          align: 'center',
          render: (h, params) => {
            return h('span',
              GetCurrentSchedule(params.row.startDate, params.row.endDate, false)
            )
          }
        },
        charge: {
          title: '记账金额',
          width: 150,
          key: 'charge',
          render: (h, params) => {
            const html = []
            if (params.row.taskitemCharges && params.row.taskitemCharges.length) {
              params.row.taskitemCharges.forEach(item => {
                const innerHtml = h('div', [
                  h('span', `${item.costTypeName}：`),
                  h('span', {
                    style: { color: '#44bd32', fontWeight: 'bold' }
                  }, toMoney(item.confirmCost))
                ])
                html.push(innerHtml)
              })
            }
            return h('div', html)
          }
        },
        qualityTestTime: { title: '质检时间', key: 'qualityTestTime', minWidth: 90 },
        createCompanyName: {
          title: '发起方',
          key: 'createCompanyName',
          minWidth: 140,
          align: 'center',
          render: (h, { row }) => {
            return h('span', row.createCompanyName || '/')
          }
        },
        supplier: {
          title: '供应商',
          minWidth: 140,
          align: 'center',
          render: (h, { row }) => {
            return h('span', row.taskitemLabor.companyName || '/')
          }
        },
        status: {
          title: '状态',
          width: 120,
          key: 'status',
          align: 'center',
          render: (h, data) => {
            // return h('tag', { props: { color: formatMspPlanStatusColor(data.row.status) } }, data.row.statusName)
            return h('div', [
              formartMspPlanStatus(h, data.row.status, data.row.statusName),
              formartMspChargeStatus(h, data.row.chargeStatus, data.row.chargeStatusName),
              formartMspTaskitemRephotoStatus(h, data.row.rephoto)
            ])
          }
        },
        qualityTesting: {
          title: '质检',
          width: 80,
          key: 'qualityTesting',
          align: 'center',
          render: (h, data) => {
            if (data.row.qualityTesting) {
              return h('Icon', {
                props: { type: 'md-checkbox-outline', size: 16, color: '#19be6b' }
              })
            } else {
              return h('Icon', {
                props: { type: 'md-close', size: 16, color: '#ed4014' }
              })
            }
          }
        },
        rephoto: {
          title: '重拍',
          width: 80,
          key: 'rephoto',
          align: 'center',
          render: (h, data) => {
            if (data.row.rephoto) {
              return h('Icon', {
                props: { type: 'md-checkbox-outline', size: 16, color: '#19be6b' }
              })
            } else {
              return h('Icon', {
                props: { type: 'md-close', size: 16, color: '#ed4014' }
              })
            }
          }
        },
        operate: {
          title: '操作',
          minWidth: 180,
          fixed: 'right',
          key: 'operate',
          align: 'center',
          render: (h, params) => {
            const operation = []
            if (params.row.type === 6) { // 测量任务
              operation.push(h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.fastCheck = false
                    this.$refs.SvgMaintenanceTask.initPageData(params.row, true)
                  }
                }
              }, '详情'))
            } else {
              operation.push(h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.fastCheck = false
                    this.$refs.modalPlanAttachInfo.loadPlanInfo([params.row], false)
                  }
                }
              }, '详情'))
              if (params.row.resourcetypeId === 400 && params.row.status === 3 && !params.row.qualityTesting && this.isAuth('inspection_index_train')) { // 列车已完成上刊任务
                operation.push(h('a', {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.$refs.trainList.initData({ assetId: params.row.assetId, taskitemId: params.row.id })
                    }
                  }
                }, '确认列车'))
              }
              if ([-1, 2].includes(params.row.taskitemFileFillWay) && params.row.status === 3 && this.isAuth('inspection_index_remake')) {
                operation.push(h('a', {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.handleSetFiles(params.row)
                    }
                  }
                }, '素材补充')
                )
              }
            }
            if ([1, 3, 7, 11].includes(params.row.type) && params.row.picturesource === 1) {
              // 上刊，下刊，制作，画面填充任务查看关联任务
              operation.push(h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.handleShowTask(params.row)
                  }
                }
              }, '关联任务'))
            }
            if (this.elecDeviceIds.includes(params.row.resourcetypeId) && params.row.status === 2 && [1, 2].includes(params.row.type)) {
              // 状态为执行中的电子屏上刊和监播任务
              operation.push(h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.onSetFeedbackPic(params.row)
                  }
                }
              }, '设置反馈素材'))
            }
            if (params.row.chargeStatus === 1 && this.isAuth('inspection_index_fee')) { // 已完成未质检
              operation.push(h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    const newParams = Object.assign(params.row, { isFastCheck: false })
                    this.onAdjustCost(newParams)
                  }
                }
              }, '调整'))
            }

            return h('div', operation)
          }
        }
      },
      selectedResourceArray: [],
      tempSelectedResourceIds: [],
      selectedItemCheckStatusArray: [], // 所选项质检状态集合
      selectedItemTyepArray: [], // 所选项任务类型集合
      stationSvgModal: false, // 弹窗显示SVG站点地图
      // svgSearchModel: {
      //   stationId: 0
      // },
      modifyCostModal: false,
      modifyTaskitemId: null,
      taskCostArray: [],
      costId: null,
      unitArray: [
        {
          id: 0,
          name: '次'
        },
        {
          id: 1,
          name: '块'

        }, {
          id: 2,
          name: '㎡'
        }],
      costModel: {
        chargeQuantity: 1,
        chargeType: 1,
        taskitemChargeId: null,
        unitCost: 0,
        remark: ''
      },
      historylist: [],
      historyColumn: [
        {
          title: '计费数量',
          key: 'chargeQuantity',
          render: (h, params) => {
            return h('div', [h('p', params.row.chargeQuantity + '' + params.row.chargeQuantityUnit)])
          }
        },

        {
          title: '计费单价',
          key: 'chargeUnitCost',
          render: (h, params) => {
            return h('div', [h('p', toMoney(params.row.chargeUnitCost) + '/' + params.row.chargeQuantityUnit)])
          }
        },
        {
          title: '计费总价',
          key: 'companyName',
          render: (h, params) => {
            return h('div', [h('p', toMoney(params.row.cost))])
          }
        },
        { title: '变更时间', width: 160, key: 'createTime' },
        { title: '备注', ellipsis: true, tooltip: true, key: 'remark' },
        { title: '变更操作人', key: 'userName' }
      ],
      checkModal: false,
      checkData: [], // 批量质检前的提示信息
      checkColumns: [
        { title: '任务类型', align: 'center', key: 'signName' },
        { title: '任务数量', align: 'center', key: 'quantity' },
        {
          title: '计费金额',
          align: 'center',
          key: 'amount',
          render: (h, params) => {
            return h('span', toMoney(params.row.amount))
          }
        }
      ],
      showTaskModal: false,
      searchSchedlue: [],
      curPlanDetailArray: [], // 当前站点任务详情
      remakeRemark: '', // 重拍备注
      elecDeviceIds: [], // 电子媒体id集合
      isFastCheck: false // 便捷质检
    }
  },
  computed: {
    showType () {
      return this.$store.state.qualityTesting.showType
    },
    curDomainId () {
      return this.$store.state.qualityTesting.curDomainId
    },
    leftPage () {
      return this.$store.state.qualityTesting.leftPage
    },
    taskTypeArray () {
      return this.$store.state.qualityTesting.taskTypeArray
    },
    statusArray () {
      return this.$store.state.qualityTesting.statusArray
    },
    curPlanKey () {
      return this.$store.state.qualityTesting.curPlanKey
    },
    planData () { // 左侧按作业日期查询的列表数据
      return this.$store.state.qualityTesting.planData
    },
    planDetailQuery: {
      get () {
        return this.$store.state.qualityTesting.planDetailQuery
      },
      set (val) {
        this.$store.commit('set_plan_detail_query', val)
      }
    },
    tableData: {
      get () {
        const result = this.$store.state.qualityTesting.planDetailData
        if (result.list && result.list.length) {
          result.list.forEach(item => {
            item._disabled = item.status !== 3
            item._checked = this.selectedResourceArray.some(s => s === item.id)
          })
        }
        return result
      },
      set (val) {
        this.$store.commit('set_plan_detail_data', val)
      }
    }
  },
  created () {
    this.getElecDeviceIds()
  },
  methods: {
    getTableColumns () {
      const data = []
      var showColumns = this.columns.get(this.showType)
      if (!showColumns || showColumns.length === 0) {
        showColumns = this.columns.get(1)
      }
      showColumns.forEach(col => data.push(this.tableColumnArray[col]))

      return data
    },
    initData () {
      this.selectedResourceArray = []
      this.selectedItemCheckStatusArray = []
      this.tempSelectedResourceIds = []
      const query = {
        keyword: this.planDetailQuery.keyword
      }
      if (this.showType === 2) { // 按客户品牌
        this.planDetailQuery.actionDate = this.planDetailQuery.actionDate ? ParseDate(this.planDetailQuery.actionDate) : ''
        query.actionDate = this.planDetailQuery.actionDate
      }
      this.$store.dispatch('getPlanDetailData', query)
    },
    handleSummary ({ columns, data }) {
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计'
          }
        } else {
          if (key === 'charge') {
            // 记账金额
            let val = 0
            data.forEach(d => {
              d.taskitemCharges.forEach(item => {
                val += item.confirmCost
              })
            })

            sums[key] = {
              key,
              value: toMoney(val)
            }
          } else {
            sums[key] = {
              key,
              value: '-'
            }
          }
        }
      })
      return sums
    },
    changePageSize (pagesize) {
      this.planDetailQuery.pageNumber = 1
      this.planDetailQuery.pageSize = pagesize
      this.selectedResourceArray = []
      this.selectedItemCheckStatusArray = []
      this.initData()
    },
    handlePageNumberChange (page) {
      this.planDetailQuery.pageNumber = page
      this.initData()
    },
    handleSearchList () {
      this.planDetailQuery.pageNumber = 1
      this.selectedResourceArray = []
      this.selectedItemCheckStatusArray = []
      this.initData()
    },
    selectionTable (selection) {
      // this.selectedResourceArray = selection.map(x => x.id)
      this.handelPageSelections(this.tempSelectedResourceIds, selection.map(x => { return { id: x.id, qualityTesting: x.qualityTesting, chargeStatus: x.chargeStatus, type: x.type } }), this.planDetailQuery.pageNumber)
      // 最终选中的所有taskItemIds
      this.selectedResourceArray = []
      this.selectedItemCheckStatusArray = []
      this.selectedItemTyepArray = []
      this.tempSelectedResourceIds.forEach(item => {
        this.selectedResourceArray = this.selectedResourceArray.concat(item.existSelections.map(x => x.id))
        this.selectedItemCheckStatusArray = this.selectedItemCheckStatusArray.concat(item.existSelections.map(x => {
          return { qualityTesting: x.qualityTesting, chargeStatus: x.chargeStatus }
        }))
        this.selectedItemTyepArray = this.selectedItemTyepArray.concat(item.existSelections.map(x => x.type))
      })
    },
    /**
     * 确认质检-同时发起质检和对账
     * @param {Object} data 默认不传，便捷质检时，传选中的任务id集合
     */
    handleConfirmTask (data, isFastCheck = false) {
      if (data) {
        this.selectedResourceArray = data
      }
      if (this.selectedResourceArray.length === 0) {
        this.$Notice.warning({ desc: '请勾选需要确认的作业任务条目！' })
        return
      }
      this.isFastCheck = isFastCheck

      getPlanTasktypeFeeStatistic({ taskitemIds: JSON.stringify(this.selectedResourceArray) }).then(res => {
        if (res && !res.errcode) {
          this.checkData = res
        } else {
          this.checkData = []
        }
      })
      this.checkModal = true
    },
    /**
     * 最终确认提交质检
     */
    handleConfirmCheck () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '您确认要将勾选的作业任务设置为已质检，并发起对账吗？',
        onOk: () => {
          const postData = {
            taskitemIds: JSON.stringify(this.selectedResourceArray)
          }

          confirmFinish(postData).then(res => {
            if (res && res.errcode === 0) {
              this.handleCheckSuccess(res)
            }
          })
        }
      })
    },
    /**
     * 确认质检-只质检
     */
    handleCheckTask (seletedIds = []) {
      // this.$Modal.confirm({
      //   title: '操作提示',
      //   content: '您确认要将勾选的作业任务设置为已质检吗？',
      //   onOk: () => {
      //     const postData = {
      //       taskitemIds: JSON.stringify(this.selectedResourceArray)
      //     }
      //     confirmTaskFinish(postData).then(res => {
      //       if (res && res.errcode === 0) {
      //         this.handleCheckSuccess(res)
      //       }
      //     })
      //   }
      // })
      if (seletedIds && seletedIds.length) {
        this.selectedResourceArray = seletedIds
      }
      this.$refs.checkTask.init(this.selectedResourceArray)
    },
    /**
    * 确认质检-只发起对账
    */
    handleCheckAmount () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '您确认要将勾选的作业任务发起对账？',
        onOk: () => {
          const postData = {
            taskitemIds: JSON.stringify(this.selectedResourceArray)
          }
          confirmAmount(postData).then(res => {
            if (res && res.errcode === 0) {
              this.handleCheckSuccess(res)
            }
          })
        }
      })
    },

    handleCheckSuccess (res) {
      // if (res && res.errcode === 0) {
      if (this.showType === 1) {
        // 按作业日期显示
        if (this.leftPage === 'LIST') {
          let pageNumber = this.planData.pageNumber
          if (pageNumber > 1 && this.planData.list.length === 1) {
            const curPlanInfo = this.planData.list[0]
            // 当前未质检任务数量和勾选质检数量相等
            if ((curPlanInfo.totalCount - curPlanInfo.qualityCount) === this.selectedResourceArray.length) {
              pageNumber--
            }
          }

          this.$store.dispatch('getPlanData', { pageNumber })
        }
        // const postData = { actionDate: this.planDetailQuery.actionDate, domainId: this.planDetailQuery.domainId }
        // // 加载右侧统计数据
        // this.$store.dispatch('getTaskStatusStatData', postData)
        // this.$store.dispatch('getTaskTypeStatData', postData)
        // this.$store.dispatch('getTaskAmountStatData', postData)
      }
      this.$Notice.success({ desc: '操作成功' })
      this.selectedResourceArray = []
      this.selectedItemCheckStatusArray = []
      this.$Modal.remove()
      // 关闭详情弹窗
      this.$refs.modalPlanAttachInfo.onCloseModal()
      this.initData()
      // 便捷质检执行
      if (this.isFastCheck) {
        // 调用便捷质检组件数据加载
        this.$refs.fastCheck.init()
      }

      // this.$emit('parentMethod', postData)
      // } else {
      //   this.$Notice.error({ title: '确认作业操作失败', desc: res.errmsg })
      // }
    },

    /**
     * 重拍
     * @param {Object} data 默认不传，便捷质检时，传选中的任务id集合
     * @param {Boolean} isFastCheck
     */
    handleRemakePhotoTask (data, isFastCheck = false) {
      if (data) {
        this.selectedResourceArray = data
      }
      if (this.selectedResourceArray.length === 0) {
        this.$Notice.warning({ desc: '请勾选需要重拍的任务条目！' })
        return
      }

      if (this.selectedItemTyepArray.includes(3)) {
        this.$Notice.warning({ desc: '当前勾选任务中包含有制作任务，制作任务无法进行重拍操作！' })
        return
      }
      this.isFastCheck = isFastCheck

      this.$Modal.confirm({
        title: '操作提示',
        render: (h) => {
          return h('div', [
            h('p', {
              style: { marginBottom: '10px' }
            }, '确定要对勾选的作业任务发起重拍？'),
            h('Input', {
              props: {
                value: this.remakeRemark,
                autofocus: true,
                size: 'small',
                type: 'textarea',
                rows: 2,
                placeholder: '填写重拍备注（非必填）'
              },
              on: {
                input: (val) => {
                  this.remakeRemark = val
                }
              }
            })
          ])
        },
        onOk: () => {
          const postData = {
            taskitemIds: JSON.stringify(this.selectedResourceArray),
            remark: this.remakeRemark
          }

          remakePhoto(postData).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '已成功发起重拍任务' })
              this.selectedResourceArray = []
              this.selectedItemCheckStatusArray = []
              this.remakeRemark = ''
              this.initData()
              // 便捷质检执行
              if (this.isFastCheck) {
                // 调用便捷质检组件数据加载
                this.$refs.fastCheck.init()
              }
            } else {
              this.$Notice.error({ title: '重拍任务操作失败', desc: res.errmsg })
            }
          })
        }
      })
    },
    svgFinished () { // SVG站点地图加载完毕
      const query = {
        pageNumber: 1,
        pageSize: 1000,
        actionDate: this.planDetailQuery.actionDate,
        domainId: this.planDetailQuery.domainId
      }

      this.searchSchedlue = [{ startDate: this.planDetailQuery.actionDate, endDate: this.planDetailQuery.actionDate }]
      getPlanDetailPage(query).then(res => {
        this.curPlanDetailArray = res.list
        this.$refs.svgStation.tagResource(res.list)
        this.$refs.svgStation.choseSpinShow()
      })
    },
    threeFinished () { // 3d场景加载完毕
      const query = {
        pageNumber: 1,
        pageSize: 1000,
        actionDate: this.planDetailQuery.actionDate,
        domainId: this.planDetailQuery.domainId
      }

      this.searchSchedlue = [{ startDate: this.planDetailQuery.actionDate, endDate: this.planDetailQuery.actionDate }]
      getPlanDetailPage(query).then(res => {
        this.$refs.threeStation.tagResource(res.list)
        // 开始标注灯箱上刊画面
        const resourceImgs = res.list.map(item => {
          return {
            deviceId: item.deviceId,
            imgUrl: item.taskitemFiles.length > 0 ? item.taskitemFiles[0].fileKey : ''
          }
        })

        this.$refs.threeStation.tagResourceImg(resourceImgs)
      })
    },
    handleChangeSvg () {
      this.stationSvgModal = true
    },
    handleSelectSvgResource (deviceId) {
      const curDeviceInfo = this.curPlanDetailArray.find(x => x.deviceId === deviceId && x.type !== 3)
      const postData = {
        actionDate: this.planDetailQuery.actionDate,
        resourceId: deviceId,
        taskitemType: curDeviceInfo ? curDeviceInfo.type : null
      }

      getTaskItemList(postData).then(res => {
        this.$refs.modalPlanAttachInfo.loadPlanInfo(res, false)
      })
    },
    handleSetFiles (params) {
      this.$store.commit('set_task_info', params)
      this.setLeftComponent('SetFiles')
    },
    formartMoney (number) {
      return toMoney(number)
    },
    /**
     * 调整计费
     * @param {Object} params
     */
    onAdjustCost (params) {
      this.modifyCostModal = true
      this.isFastCheck = params.isFastCheck
      this.modifyTaskitemId = params.id
      this.taskCostArray = params.taskitemCharges
      if (this.taskCostArray && this.taskCostArray.length) {
        this.costId = this.taskCostArray[0].id
        this.costModel.chargeQuantity = this.taskCostArray[0].confirmChargeQuantity
        this.costModel.chargeType = this.taskCostArray[0].confirmChargeType
        this.costModel.unitCost = this.taskCostArray[0].confirmChargeUnitCost
      }
      this.getCostModifyData()
    },
    /**
     * 获取计费更改记录
     */
    getCostModifyData () {
      getFeeModifyList({ taskitemId: this.modifyTaskitemId }).then(res => {
        if (res && !res.errcode) {
          this.historylist = res
        } else {
          this.historylist = []
        }
      })
    },
    handleChoiceCost () {
      const curCost = this.taskCostArray.find(x => x.id === this.costId)
      this.costModel.chargeQuantity = curCost.confirmChargeQuantity
      this.costModel.chargeType = curCost.confirmChargeType
      this.costModel.unitCost = curCost.confirmChargeUnitCost
    },
    confirmUpdate () {
      if (!this.costId) {
        this.$Notice.warning({ desc: '请选择一项计费进行更改' })
        return false
      }
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要进行更改？',
        onOk: () => {
          this.costModel.taskitemChargeId = this.costId
          modifycharge(this.costModel).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.getCostModifyData()
              this.initData()
              if (this.isFastCheck) {
                // 调用便捷质检组件数据加载
                this.$refs.fastCheck.init()
              }
            }
          }).finally(() => { this.modifyCostModal = false })
        }
      })
    },
    /**
     * 显示关联任务
     */
    handleShowTask (params) {
      this.$refs.relationTask.initShow({ taskitemId: params.id, taskitemType: params.type })
    },
    handleBeginRevice3d (stationId) {
      this.$refs.threeStation.initData(stationId)
    },
    /**
     * 获取电子屏id集合
     */
    getElecDeviceIds () {
      this.elecDeviceIds = []
      getElecDeviceModelList().then(res => {
        if (res && !res.errcode) {
          this.elecDeviceIds = res
        }
      })
    },
    /**
     * 设置反馈素材
     */
    onSetFeedbackPic (params) {
      this.isFastCheck = false
      const postData = {
        taskId: params.taskId,
        taskitemId: params.id
      }
      this.$refs.setFeedbackPic.init(postData)
    },
    onFastCheck () {
      // 清除列表临时选中项数据
      this.selectedResourceArray = []
      this.selectedItemCheckStatusArray = []
      this.$refs.fastCheck.init()
    },
    onShowSkuTask (row) {
      this.$nextTick(() => {
        this.$refs.skuTask.showModal(row)
      })
    }
  },
  watch: {
    showType: {
      deep: true,
      immediate: true,
      handler (val) {
        this.tableColumns = this.getTableColumns()
      }
    },
    curPlanKey (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectedResourceArray = []
        this.selectedItemCheckStatusArray = []
        this.tempSelectedResourceIds = []
        this.btnConfirm = false
        this.btnCheckAmount = false
      }
    },
    selectedItemCheckStatusArray: { // 勾选项质检状态集合
      deep: true,
      immediate: true,
      handler (val) {
        this.btnConfirm = true
        this.btnCheckAmount = true
        if (val.length) {
          if (val.every(x => !x.qualityTesting)) {
            // 勾选项质检状态全为false，确认质检按钮取消禁用
            this.btnConfirm = false
          }
          if (val.every(x => x.qualityTesting && x.chargeStatus === 1)) {
            // 勾选项质检状态全为true，对账状态为1，发起对账按钮取消禁用
            this.btnCheckAmount = false
          }
        }
      }
    }
  }
}
</script>
